import LogService from '@/services/LogService';

const payoutTableMixin = {
  methods: {
    updateRainyDaysStrings(weatherDirection, dates) {
      this.rainy_day = this.str.done.singular_day[weatherDirection];
      this.rainy_days = this.str.done.plural_days[weatherDirection];
      LogService.log('updateRainyDaysStrings', dates.length);
      this.noDays = dates.length;
    },
  },
  data() {
    return {
      rainy_day: '',
      rainy_days: '',
      noDays: 0,
    };
  },
};

export default payoutTableMixin;
