import LogService from '@/services/LogService';
import { availableWeatherDirs } from '@/constants';
import '@/mixins/common';

const weatherParameterContext = {
  methods: {
    weather_parameter_context(
      doneStrings,
      weather_type,
      weather_direction,
      threshold,
      thresholdUnit,
    ) {
      LogService.log(
        'Done, weather_parameter_subtext',
        weather_type,
        weather_direction,
        threshold,
        thresholdUnit,
      );
      if (doneStrings?.severity_description) {
        const firstPart = doneStrings?.severity_description && weather_type
          ? doneStrings?.severity_description[`first_part_${weather_type}`]
          : '';

        const comparison = weather_direction
        && [
          availableWeatherDirs.cold,
          availableWeatherDirs.nosnow,
        ].includes(weather_direction)
          ? doneStrings?.severity_description
          && doneStrings?.severity_description.less_than_or_equal
          : doneStrings?.severity_description
          && doneStrings?.severity_description.more_than;

        const level = `<strong>${threshold || 0}${thresholdUnit}</strong>`;
        const example = this.weather_parameter_context_example(
          weather_type,
          threshold,
          doneStrings?.severity_description,
        );
        LogService.log('weatherParameterContext, example', example);

        return this.format(
          doneStrings?.severity_description?.template,
          firstPart,
          comparison,
          level,
          example,
        );
      }
      return '';
    },
    weather_parameter_context_example(
      weather_type,
      threshold,
      severity_description,
    ) {
      if (severity_description) {
        const ranges = Object.entries(severity_description)
          .filter((el) => {
            // el = ['rain_step_1_more', '30']
            const regex = new RegExp(`${weather_type}_step_\\d+_(less|more)`, 'g');
            return regex.test(el[0]);
          });

        let stringIndex = null;

        const check = (num) => ranges.some(
          (el) => new RegExp(
            `${weather_type}_step_${num}_(less|more)`,
            'g',
          ).test(el[0]),
        );

        let ii = 1;
        while (check(ii)) {
          if (
            threshold
            >= Number.parseInt(severity_description[`${weather_type}_step_${ii}_more`], 10)
            && threshold
            < Number.parseInt(severity_description[`${weather_type}_step_${ii}_less`], 10)
          ) {
            stringIndex = ii;
          }
          ii += 1;
        }

        const endPart = severity_description[`${weather_type}_step_${stringIndex}`];
        return this.format(severity_description.example, endPart);
      }

      return '';
    },
  },
};

export default weatherParameterContext;
