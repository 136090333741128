<template>
  <table-ui
    :bodyHeight="220"
    :cols="['numdays', 'payout']"
    :head="{
      numdays: {
        text: `${header_number_of_days} ${rainy_days_str}`,
      },
      payout: {
        text: your_payout_text,
        align: 'right',
      },
    }"
  >
    <template v-slot:body>
      <template v-for="(item, index) in rainy_days">
        <tr
          :key="`${index}-wet-table-tr`"
          :class="hasHistorical(item) ? 'wet-table-have-been' : ''"
        >
          <td colspan="2">
            <div>
              <div class="d-flex">
                <div class="flex-grow-1">
                  <strong>{{item[0]}} </strong>
                  <span class="font-weight-normal">
                    {{[-1,1].includes(item[0]) ? rainy_day_str : rainy_days_str}}
                  </span>
                </div>
                <div
                  class="flex-shrink-0 text-right text-nowrap"
                  :class="index === 0 ? 'font-weight-bold is-size-5' : ''"
                >
                  {{numToPriceFormat(item[1], lang)}} {{ currency }}
                </div>
                <div
                  :key="`${index}-wet-table-sub-tr`"
                  v-if="hasHistorical(item[1])"
                  class="is-size-7 text-primary text-right pt-2"
                >
                  {{ computeText(item) }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </template>
  </table-ui>
</template>
<script>
import done from '@/mixins/done';
import { mapState } from 'vuex';
import common from '@/mixins/common';
import tableUi from '@/components/ui/tableUi.vue';
import LogService from '@/services/LogService';

export default {
  mixins: [done, common],
  components: {
    tableUi,
  },
  props: {
    header_number_of_days: {
      type: String,
      required: true,
    },
    rainy_days_str: {
      type: String,
      required: true,
    },
    rainy_day_str: {
      type: String,
      required: true,
    },
    your_payout_text: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      default: '',
    },
    historical: {
      type: Object,
      default: () => undefined,
    },
    contract_days: {
      type: Number,
      required: true,
    },
    weather_direction: {
      type: String,
      required: true,
    },
    daily_payout: {
      type: Number,
      required: true,
    },
    risk_days: {
      type: Number,
      required: true,
    },
    historical_payout_template_text: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['lang']),
    rainy_days() {
      // calc data for table
      const days = [];
      if (
        this.weather_direction !== this.availableWeatherDirs.norain
      ) {
        for (let index = this.contract_days; index >= this.risk_days; index -= 1) {
          days.push([
            index,
            this.toCent(this.daily_payout * (index - this.risk_days)),
          ]);
        }
        LogService.log('PayoutTable, days', days);
      } else {
        const lastDay = this.contract_days;
        for (let index = lastDay; index >= 0; index -= 1) {
          LogService.log('push day', lastDay - index);
          days.push([
            lastDay - index,
            this.toCent(this.daily_payout * index),
          ]);
        }
      }
      return days;
    },
    historicalLimited() {
      const startYear = process.env.VUE_APP_ALMOST_DONE_SHOW_PAYOUT_TABLE_START_YEAR;
      const endYear = process.env.VUE_APP_ALMOST_DONE_SHOW_PAYOUT_TABLE_END_YEAR;
      LogService.log('PayoutTable, historical:', this.historical);
      let historical = Object.entries(this.historical)
        .filter(([key]) => Number(key) >= startYear && Number(key) <= endYear);
      historical = Object.fromEntries(historical);

      return historical;
    },
  },
  methods: {
    /**
     * @return true if table row has hitorical
     */
    hasHistorical(payout) {
      const emptyHist = this.historical === undefined;
      if (emptyHist) {
        return false;
      }
      return payout !== 0
        && Object.values(this.historicalLimited || {}).includes(payout);
    },
    computeText(item) {
      const emptyHist = this.historical // 👈 null and undefined check
        && Object.keys(this.historical).length === 0
        && Object.getPrototypeOf(this.historical) === Object.prototype;
      LogService.log('PayoutTable, emptyHist', emptyHist);
      if (!emptyHist) {
        const { and } = this.strings.commonApp;
        const years = Object.entries(this.historicalLimited || {})
          .filter(([, val]) => val === item[1])
          .map(([key]) => key)
          .reduce((acc, cur, i, arr) => {
            let result = acc;
            const con = i + 1 === arr.length ? ` ${and} ` : ', ';
            result += `${i ? con : ''}${cur}`;
            return result;
          }, '');
        return this.format(
          this.historical_payout_template_text,
          years,
        );
      }
      return '';
    },
  },
};
</script>
