<template>
  <div>
    <div class="wet-section">
      <div class="container">
        <div class="row wet-done">

          <!-- LEFT BLOCK WITH PARAMS -->
          <div class="col-md-6 col-12 mb-3 mb-md-0">
            <h3 class="empty-ph is-size-6">
              {{str.done.almost_done_subtitle}}
            </h3>
            <wet-conditions
              :conditions="conditions"
              @select="(v) => changeCondition(v)"
              class="pb-3 mb-3 border-bottom"
            />

            <slot name="days-selection" v-bind="{api_data, optimizedData}"></slot>

            <template v-if="!$scopedSlots['days-selection']">

              <h3 class="is-size-6">
                <span class="wet-done-number">1.</span>
                <span class="empty-ph">
                {{str.done.severity_item_title && api_data.weather_direction
                  ? str.done.severity_item_title[api_data.weather_direction]
                  : ''}}
              </span>
              </h3>
              <div class="wet-levels">
                <div
                  :id="`wet-levels-button-${tile.name}`"
                  @click="$set(form_data, 'severity', tile.name); debouncedFormChange()"
                  class="wet-levels-tile"
                  :title="tile.title"
                  :class="form_data.severity === tile.name ? 'active' : ''"
                  v-for="(tile, index) in severities"
                  :key="index"
                >
                  <div class="wet-levels-tile-icon">
                    <weather-icon
                      :type="tile.icon"
                      :intensity="tile.intensity"
                      class="w-100"
                    />
                  </div>
                  <div
                    class="wet-levels-tile-title empty-ph"
                  >{{tile.title}}</div>
                </div>
              </div>
              <div
                class="wet-levels-custom"
                id="done-button-open-modal-custom-threshold"
                :class="form_data.severity === 'custom'
                || form_data.severity === null ? 'active' : ''"
                @click="$set(modalsOpened, 'customThreshold', true)"
              >
                {{str.done.custom_text_severity}}
              </div>
              <p class="wet-note empty-ph" v-html="weather_context_derive"></p>
              <h3 class="is-size-6">
                <span class="wet-done-number">2.</span>
                <span class="empty-ph">{{two_step_title}}</span>
              </h3>
              <p class="wet-note empty-ph">{{$lodash.upperFirst(format(
                str.done.how_many_rainy_days_subtitle,
                api_data.days === 1
                  ? str.done.singular_day ?
                    str.done.singular_day[api_data.weather_direction]
                    : ''
                  : str.done.plural_days
                    ? str.done.plural_days[api_data.weather_direction]
                    : '',
                api_data.contract_days,
                api_data.contract_days === 1
                  ? str.done.day
                  : str.done.days
              ))}}</p>
              <input-number
                :placeholder="`${two_step_title}...`"
                hideDetails="auto"
                labeled
                arrowsAlt
                :right="true"
                :min="minDays"
                :max="maxDays"
                :step="1"
                :decimals="0"
                class="mb-3"
                v-model.number="$v.form_data.days.$model"
                :errors="$v.form_data.days.$error ? [errorDays] : []"
                @change="onChangeDays($event)"
              />

              <!-- daily payout -->
              <h3 class="is-size-6">
                <span class="wet-done-number">3.</span>
                <span class="empty-ph">{{three_step_title}}</span>
              </h3>
              <input-number
                :id="`wet-input-payout-${isMaximalMode ? 'max' : 'daily'}`"
                :placeholder="`${three_step_title}...`"
                hideDetails="auto"
                labeled
                arrowsAlt
                :right="true"
                :min="minPayout"
                :step="1"
                :decimals="2"
                class="mb-3"
                v-model.number=
                  "$v.form_data[(isMaximalMode ? 'max_payout' : 'daily_payout')].$model"
                :errors="$v.form_data.max_payout.$error
                || $v.form_data.daily_payout.$error ? [errorPayout] : []"
                :append="currency"
                @change="onPayoutChange($event)"
              />
              <!-- THREE STEP -->
              <div class="wet-done-payout row no-gutters">
                <div class="col-auto pr-1" style="min-width:5rem;">
                  <span class="empty-ph">{{three_step_calc_title}}</span> =
                </div>
                <div
                  v-if="isMaximalMode"
                  class="col text-right text-nowrap"
                >
                  <money-field
                    :value="api_data.max_payout"
                    :dynamic-cents="true"
                    :accent="true"
                    :currency="currency"
                  />
                  <strong>/ {{ api_data.days }}</strong> {{ str.done.days }}
                  = <money-field
                  :value="api_data.daily_payout"
                  :dynamic-cents="true"
                  :accent="true"
                  :currency="currency"
                />
                </div>
                <div v-else class="col text-right text-nowrap">
                  <money-field
                    :value="api_data.daily_payout"
                    :dynamic-cents="true"
                    :accent="true"
                    :currency="currency"
                  />
                  <strong>x {{ nonInsuredDays }}</strong> {{ str.done.days }}
                  = <money-field
                  :value="api_data.max_payout"
                  :dynamic-cents="true"
                  :accent="true"
                  :currency="currency"
                />
                </div>
              </div>
              <!-- PRICE SECTION -->
              <div class="row no-gutters">
                <div class="col">
                  <h3 class="is-size-6">
                    <span class="wet-done-number">4.</span>
                    <span class="empty-ph">{{str.done.select_insurer}}</span>
                  </h3>
                  <div class="wet-note my-2">
                    {{str.done.select_insurer_sub}}
                  </div>
                  <table-ui
                    :loading="!api_data.prices"
                    :bodyHeight="220"
                    :head="{
                    insurer: { text: str.commonApp.insurer },
                    price: {
                      text: str.commonApp.price_including_taxes,
                      align: 'right',
                    },
                  }"
                    :body="(api_data.prices || []).map((p, i) => ({
                      insurer: {
                        name: p.name,
                        percent: !!p.alternative_price
                          && Math.round((1 - (p.price / p.alternative_price)) * 100),
                      },
                      price: {
                        price: p.price,
                        price_alt: p.alternative_price,
                        classes: i === 0 ? 'font-weight-bold' : '',
                      }
                    }))"
                    :radio="true"
                    :selected="(api_data.prices || []).findIndex((s) => s.id === form_data.insurer)"
                    @update:selected="
                    (s)=> $v.form_data.insurer.$model = (api_data.prices[s] || {}).id || null"
                  >
                    <template #ceil-insurer="{ item }">
                      <div>
                        <div>
                          {{ item.name }}
                        </div>
                        <div v-if="item.percent !== false" class="is-size-7">
                          {{ format(str.done.alt_price_text, item.percent) }}
                        </div>
                      </div>
                    </template>
                    <template #ceil-price="{ item }">
                      <money-field v-if="item.price_alt" crossout dynamicCents
                                  :value="item.price_alt" :currency="currency"/>
                      <br v-if="item.price_alt">
                      <money-field dynamicCents
                                  :value="item.price" :currency="currency"/>
                    </template>
                  </table-ui>
                  <div v-if="$v.form_data.insurer.$error" class="wet-form-error mt-n2 mb-2 mr-4">
                    {{ str.done.error_unselected_insurer }}
                  </div>
                </div>
              </div>

            </template>
          </div>
          <!-- END LEFT BLOCK -->

          <!-- RIGHT BLOCK WITH TABLE -->
          <div class="col col-md-6">
            <div class="wet-card">
              <payout-table
                :header_number_of_days="str.done.number_of"
                :rainy_days_str="rainy_days"
                :rainy_day_str="rainy_day"
                :your_payout_text="str.done.your_payout_text"
                :currency="currency"
                :historical="historical"
                :weather_direction="api_data.weather_direction ? api_data.weather_direction : ''"
                :contract_days="payoutTableDays"
                :risk_days="api_data.risk_days ? api_data.risk_days : 0"
                :daily_payout="api_data.daily_payout ? api_data.daily_payout : 0"
                :historical_payout_template_text="str.done.historical_payout_template_text"
              />
              <label
                id="wet-done-label-confirm"
                class="checkbox is-size-7 mb-3"
                for="wet-done-confirm"
              >
                <strong
                  class="empty-ph"
                  v-html="confirmationOfBusinessRisk"
                />
                <input
                  type="checkbox"
                  name="wet-done-confirm"
                  id="wet-done-confirm"
                  class=""
                  v-model="$v.form_data.confirm.$model"
                >
                <span id="wet-done-span-confirm"></span>
              </label>
              <div v-if="$v.form_data.confirm.$error" class="wet-form-error mt-n2 mb-2 mx-4">
                {{ str.done.error_confirm }}
              </div>
              <label
                id="wet-done-label-station"
                class="checkbox is-size-7 mb-3"
                for="wet-done-confirm-station"
                v-if="api_data.business_type === availableBusinessTypes.volksfest"
              >
                <strong class="empty-ph">{{format(
                  str.done.confirmation_of_station,
                  api_data.location.address,
                  api_data.location.station,
                  api_data.location.id,
                  api_data.location.lat,
                  api_data.location.lon,
                  api_data.location.distance,
                  api_data.location.station,
                )}}</strong>
                <input
                  type="checkbox"
                  name="wet-done-confirm-station"
                  id="wet-done-confirm-station"
                  class=""
                  v-model="$v.form_data.confirm_station.$model"
                >
                <span id="wet-done-span-station"></span>
              </label>
              <div
                v-if="$v.form_data.confirm_station.$error
                  && api_data.business_type === availableBusinessTypes.volksfest"
                class="wet-form-error mt-n2 mb-2 mx-4"
              >
                {{ str.done.error_confirm_sta }}
              </div>
            </div>
            <bars-chart
              :threshold="api_data.threshold"
              :weatherType="api_data.weather"
              :fromZero="api_data.weather !== availableWeatherTypes.temperature"
              :risk_days="api_data.risk_days"
              :thresholdUnit="thresholdUnit"
              :daily-payout="Number(api_data.daily_payout)"
              :currency="currency"
            />
          </div>
          <!-- END RIGHT BLOCK WITH TABLE -->

        </div>
      </div>
    </div>
    <wet-modal
      id="done-wet-modal-custom-threshold"
      :opened="modalsOpened.customThreshold"
      :title="str.done.custom_severity_title_modal"
      :actions="[
        {
          title: str.commonApp.cancel,
          name: 'ok-custom-threshold-cancel',
          event: 'close',
        },
        {
          title: str.commonApp.ok,
          name: 'ok-custom-threshold',
          disabled: errorCustomThreshold.length > 0,
          event: () => {
            // set severity to Custom
            this.$set(this.form_data, 'severity', 'custom');
            // call price api deboucer
            debouncedFormChange();
            // close modal
            $set(modalsOpened, 'customThreshold', false);
          },
        },
      ]"
      @close="$set(modalsOpened, 'customThreshold', false); inputCancelThreshold()"
    >
      <template v-slot:content>
        <input-number
          class="mb-3"
          labeled
          :right="true"
          :inputmode="api_data.weather === availableWeatherTypes.snow ? 'numeric' : 'decimal'"
          :step="api_data.weather === availableWeatherTypes.snow ? 1 : 0.1"
          :append="thresholdUnit"
          :decimals="api_data.weather === availableWeatherTypes.snow ? 0 : 1"
          hideDetails="auto"
          v-model="$v.form_data.threshold.$model"
          :errors="errorCustomThreshold"
          arrows
        />
      </template>
    </wet-modal>
    <wet-modal
      id="wet-price-error-modal"
      icon="error"
      :opened="modalsOpened.priceError"
      :content="modalsContent.priceError.text"
      :actions="[
        {
          title: str.commonApp.ok,
          name: 'ok-price-error',
          event: () => {
            $set(modalsOpened, 'priceError', false);
            typeof modalsContent.priceError.cb === 'function' && modalsContent.priceError.cb();
          }
        }
      ]"
      @close="$set(modalsOpened, 'priceError', false);
        typeof modalsContent.priceError.cb === 'function' && modalsContent.priceError.cb()"
    ></wet-modal>
    <modal-outside
      v-model="modalsOpened.priceOutsideError"
      :optimizedData="optimizedData"
      :selectedParams="priceCallData"
      :contractDays="api_data.contract_days"
      :maxPayout="api_data.max_payout"
      :dailyPayout="api_data.daily_payout"
      :isMaximalMode="isMaximalMode"
      :weatherType="api_data.weather"
      :minRiskDaysRatio="api_data.min_risk_days_ratio"
      :maxRiskDaysRatio="api_data.max_risk_days_ratio"
      :maxDailyPayout="maxDailyPayout"
      :maxContractPayout="maxContractPayout"
      :currency="currency"
      :insurers="api_data.insurers"
      @select="onSelectFromOutsideSet"
    />
  </div>
</template>
<script>
import {
  required,
  minValue,
  maxValue,
  integer,
} from 'vuelidate/lib/validators';
import payoutTable from '@/components/done/PayoutTable.vue';
import done from '@/mixins/done';
import common from '@/mixins/common';
import { availablePayoutInput } from '@/constants';
import weatherParameterContext from '@/mixins/weatherParameterContext';
import wpSection from '@/enums/wpSection';
import metaData from '@/enums/websiteMeta';
import businessType from '@/enums/businessTypes';
import { commonApp, doneStrings } from '@/enums/stringIndices';
import payoutTableMixin from '@/mixins/payoutTableMixin';

export default {
  name: 'DoneView',
  mixins: [done, common, weatherParameterContext, payoutTableMixin],
  props: {
    nextDisabled: Boolean,
  },
  components: {
    payoutTable,
    barsChart: () => import(/* webpackChunkName: "barsChart" */ '@/components/done/BarsChart.vue'),
    weatherIcon: () => import(/* webpackChunkName: "weatherIcon" */ '@/components/weatherIcon.vue'),
    tableUi: () => import(/* webpackChunkName: "tableUi" */ '@/components/ui/tableUi.vue'),
    wetConditions: () => import(/* webpackChunkName: "wetConditions" */ '@/components/WetConditions.vue'),
    moneyField: () => import(/* webpackChunkName: "moneyField" */ '@/components/ui/moneyFieldUi.vue'),
    modalOutside: () => import(/* webpackChunkName: "modalOutside" */ '@/components/done/ModalOutside.vue'),
    inputNumber: () => import(/* webpackChunkName: "inputNumber" */ '@/components/ui/inputNumberUi.vue'),
  },
  data() {
    return {
      orderApi: '/api/order/',
      selInsurerApi: '/api/insurer/',
      optimizerApi: '/api/order/optimizer/',
      priceApi: '/api/order/price/',
      availableBusinessTypes: businessType,
      progress: [3, 5],
      modalsOpened: {
        info: false,
        priceError: false,
        priceOutsideError: false,
        imprint: false,
        customThreshold: false,
      },
      modalsContent: {
        priceError: { text: '' },
      },
      api_data: {},
      form_data: {
        threshold: 0,
        confirm: false,
        confirm_station: false,
        insurer: null,
      },
      str: {
        done: doneStrings,
        commonApp,
      },
      priceCallData: {},
      optimizedData: [],
      userStatus: undefined,
      historical: {},
      three_step_title: '',
      three_step_calc_title: '',
      errorPayout: '',
      two_step_title: '',
      errorDays: '',
    };
  },
  validations() {
    return {
      form_data: {
        max_payout: {
          required,
          minValue: minValue(this.minPayout),
        },
        daily_payout: {
          required,
          minValue: minValue(this.minPayout),
        },
        days: {
          required,
          minValue: minValue(this.minDays),
          maxValue: maxValue(this.maxDays),
        },
        threshold: {
          required,
          minValue: this.minCustomThreshold === undefined || minValue(this.minCustomThreshold),
          maxValue: this.maxCustomThreshold === undefined || maxValue(this.maxCustomThreshold),
          integer: this.api_data.weather !== this.availableWeatherTypes.snow || integer,
        },
        confirm: {
          checked: (val) => val,
        },
        confirm_station: {
          checked: (val) => this.api_data.business_type !== this.availableBusinessTypes.volksfest
            || val,
        },
        insurer: {
          selected: (val) => ![null, undefined, '', false].includes(val),
        },
      },
    };
  },
  computed: {
    conditions() {
      const weatherType = this.api_data.weather;
      const weatherDir = this.api_data.weather_direction;
      return [
        {
          icon: weatherType,
          iconComponent: 'weatherIcon',
          title: this.str.done.weather,
          link: {
            title: this.str.commonApp?.weather_direction?.[weatherDir] || '',
            to: this.links.weather,
          },
        },
        {
          icon: 'calendar',
          iconComponent: 'wetIcon',
          title: this.str.done.period,
          link: {
            title: `${this.api_data.contract_days} ${this.str.done.days}`,
            to: this.links.date,
          },
        },
        {
          icon: 'location',
          iconComponent: 'wetIcon',
          title: this.str.done.location,
          link: {
            title: this.api_data.location?.address,
            to: this.links.location,
          },
        },
      ];
    },
    severities() {
      let weatherType = '';
      for (let i = 0; i < Object.entries(this.availableWeatherTypes).length; i += 1) {
        const [value] = Object.entries(this.availableWeatherTypes)[i];
        if (value === this.api_data.weather) {
          weatherType = value;
          break;
        }
      }
      let weatherDirection = '';
      for (let i = 0; i < Object.entries(this.availableWeatherDirs).length; i += 1) {
        const [value] = Object.entries(this.availableWeatherDirs)[i];
        if (value === this.api_data.weather_direction) {
          weatherDirection = value;
          break;
        }
      }
      return [
        {
          icon: weatherType,
          intensity: 'low',
          title: weatherDirection !== '' && this.str.done.low_text ? this.str.done.low_text[weatherDirection] : '',
          name: 'low',
        },
        {
          icon: weatherType,
          intensity: 'middle',
          title: weatherDirection !== '' && this.str.done.middle_text ? this.str.done?.middle_text[weatherDirection] : '',
          name: 'middle',
        },
        {
          icon: weatherType,
          intensity: 'high',
          title: weatherDirection !== '' && this.str.done?.high_text ? this.str.done?.high_text[weatherDirection] : '',
          name: 'high',
        },
      ];
    },
    thresholdUnit() {
      let unit = 'mm';
      switch (this.api_data.weather) {
      case this.availableWeatherTypes.snow:
        unit = 'cm';
        break;
      case this.availableWeatherTypes.temperature:
        unit = '°C';
        break;
      default:
      }
      return unit;
    },
    weather_context_derive() {
      return this.weather_parameter_context(
        this.str.done,
        this.api_data.weather,
        this.api_data.weather_direction,
        this.api_data.threshold,
        this.thresholdUnit,
      );
    },
    nonInsuredDays() {
      return this.api_data.contract_days - this.api_data.days;
    },
    payoutTableDays() {
      if (this.api_data.business_type === 'crop') {
        return this.api_data.days;
      }
      return this.api_data.contract_days ?? 0;
    },
    links() {
      const date = {
        name: 'rain',
        query: {
          redirect: 'done',
          business_type: this.api_data.business_type,
          weather_direction: this.api_data.weather_direction,
          keep_order: 1,
        },
        params: { ...this.$route.params },
      };
      const location = { name: 'location', query: { redirect: 'done' }, params: { ...this.$route.params } };

      const dates = (this.api_data.dates || []).map((d) => this.$moment(d, 'YYYY-MM-DD', true)).filter((d) => d.isValid());
      const startDate = this.$moment.min(dates).format('YYYY-MM-DD');
      const endDate = this.$moment.max(dates).format('YYYY-MM-DD');

      const eH = Math.max(...(this.api_data.hours || []).map((el) => Number(el)));
      switch (this.api_data.business_type) {
      case this.availableBusinessTypes.crop:
        date.name = 'drought';
        date.query.start_date = startDate;
        date.query.end_date = endDate;
        date.query.crop = this.api_data.crop;
        break;
      case this.availableBusinessTypes.volksfest:
      case this.availableBusinessTypes.event:
      case this.availableBusinessTypes.restaurant:
        date.name = 'rain';
        date.query.dates = this.api_data.dates.join(',');
        date.query.start_hour = Math.min(...(this.api_data.hours || []).map((el) => Number(el)));
        date.query.end_hour = eH ? eH + 1 : undefined;
        break;
      case this.availableBusinessTypes.custom:
        date.name = 'custom';
        date.query.dates = this.api_data.dates.join(',');
        date.query.branch_id = this.api_data.branchId;
        break;
      case this.availableBusinessTypes.hotel:
        date.name = 'hotel';
        date.query.start_date = startDate;
        date.query.end_date = endDate;

        location.name = 'hotel';
        break;
      default:
        break;
      }
      return {
        weather: { href: '/home', path: '/home' },
        date,
        location,
      };
    },
    confirmationOfBusinessRisk() {
      if (this.str.done.confirmation_of_business_risk) {
        return this.str.done.confirmation_of_business_risk
          ?.filter((t) => t.insurer_id === this.form_data.insurer?.toString())?.[0]?.label;
      }
      return undefined;
    },
    isMaximalMode() {
      return this.api_data.payout_input === 'maximal_payout';
    },
    maxDailyPayout() {
      return this.api_data.maximal_value_daily_payout;
    },
    maxContractPayout() {
      return this.api_data.maximal_value_max_contract_payout;
    },
    minCustomThreshold() {
      return this.api_data.minThreshold;
    },
    maxCustomThreshold() {
      return this.api_data.maxThreshold;
    },
    currency() {
      return this.api_data.currency;
    },
    errorCustomThreshold() {
      const error = this.format(this.str.done.error_threshold_outside,
        this.minCustomThreshold, this.maxCustomThreshold);
      return this.$v.form_data.threshold.$error ? [error] : [];
    },
    minDays() {
      return this.isMaximalMode ? 1 : 0;
    },
    maxDays() {
      const datesLength = this.api_data.dates?.length || 0;
      const maxDays = this.isMaximalMode ? datesLength : datesLength - 1;
      return Math.max(0, maxDays);
    },
    minPayout() {
      return 0;
    },
  },
  watch: {
    'form_data.daily_payout': {
      immediate: true,
      handler() {
        this.$v.form_data.daily_payout.$touch();
        if (this.$v.form_data.daily_payout.$error) return;

        this.calcPayout();
      },
    },
    'form_data.max_payout': {
      immediate: false,
      handler() {
        this.$v.form_data.max_payout.$touch();
      },
    },
    'form_data.days': {
      immediate: true,
      handler() {
        this.$v.form_data.days.$touch();
        if (this.$v.form_data.days.$error) return;

        this.calcPayout();
      },
    },
  },
  async created() {
    await Promise.all([
      this.getWordPressStrings([wpSection.DONE, wpSection.COMMON_APP], wpSection.DONE),
      this.$store.commit('SET_APP_LAYOUT', { ...this.appLayout, progress: this.progress }),
      this.$root.$on('buttonNext', this.next),
      this.$root.$on('buttonBack', this.back),
      this.$emit('update:nextDisabled', false),
    ]);

    // debounce form changes
    this.debouncedFormChange = this.$lodash.debounce(this.onFormChange, 200);

    // set next to enabled

    try {
      // get api data
      await this.getOrderApi();
      await this.updateStrings(this.api_data.weather_direction);

      // push to data layer
      this.pushDataLayer();

      // call optimizer api
      await this.callOptimizer();
      this.setOptimized();

      // call price api
      await this.callPriceApi()
        .then((priceApiData) => this.updateOrderData(priceApiData));
    } catch (error) {
      console.error(error);
    }
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
  methods: {
    /** get order data */
    async getOrderApi() {
      let res;

      try {
        res = await this.callApi(this.orderApi);
        // map returned data
        this.api_data = this.mapGetOrderData(res);
        this.userStatus = res.data.user?.status?.toString();
      } catch (e) {
        if (e.response?.status === 400) {
          window.location = '/';
        }
      }

      if (!Object.values(availablePayoutInput).includes(this.api_data.payout_input)) {
        throw new Error('Order \'payout_input\' field is not correct!');
      }
    },
    async selectInsurer(id) {
      /** select insurer call api */
      await this.callApi({ url: `${this.selInsurerApi}${id}`, method: 'post' });
    },
    updateStrings(weatherDirection) {
      this.updateRainyDaysStrings(weatherDirection, this.api_data.dates);
      this.three_step_title = this.str.done.payout_title[weatherDirection];
      this.three_step_calc_title = this.str.done.total_label[weatherDirection];
      this.errorPayout = this.format(
        this.str.done.error_payout_outside_tpl,
        this.three_step_title,
        this.minPayout,
      );
      const byWeatherType = this.str.done.by_weather_type
        ?.filter((t) => t.weather_type === weatherDirection)[0] || {};
      this.two_step_title = this
        .format(byWeatherType.rainy_days_title_tpl, byWeatherType.entity_name);
      this.errorDays = this.format(
        this.str.done.error_days_outside_tpl,
        byWeatherType.entity_name,
        this.minDays,
        this.maxDays,
      );
    },
    async callOptimizer() {
      // search optimized initial data
      const data = {
        start_year: process.env.VUE_APP_OPTIMIZER_START_YEAR,
        end_year: process.env.VUE_APP_OPTIMIZER_END_YEAR,
      };

      const res = await this.callApi({ url: this.optimizerApi, method: 'post', data })
        .catch(() => {});
      this.optimizedData = res.data || [];
    },
    setOptimized() {
      // eslint-disable-next-line max-len
      const [optimized] = this.optimizedData.filter((el) => el.f_max_ratio_bundle_avg_hist_payout_bundle_min_price);
      if (optimized) {
        const newApiData = this.getApiDataFromOptimized(this.api_data, optimized);
        this.api_data = newApiData;

        this.form_data = {
          ...this.form_data,
          ...newApiData,
        };
      }
    },
    async getPrice(data) {
      return this.callApi({ url: this.priceApi, method: 'post', data });
    },
    /** call price api & upd api_data if api success */
    async callPriceApi() {
      const riskDays = this.isMaximalMode
        ? this.form_data.contract_days - this.form_data.days : this.form_data.days;

      const data = {
        daily_payout: Number.parseFloat(this.form_data.daily_payout),
        risk_days: riskDays,
      };

      if (this.form_data.severity === 'custom' || this.form_data.severity === null) {
        data.threshold = this.form_data.threshold;
      } else {
        data.weather_severity = this.form_data.severity;
      }

      this.priceCallData = data;
      const res = await this.getPrice(data)
        .catch((e) => {
          if (e.response?.data?.message) {
            if (e.response.status === 400 && (new RegExp(this.errorStrings.invalidUser, 'i')).test(e.response.data.message)) {
              if (process.env.NODE_ENV !== 'development') {
                window.location = '/';
              }
            }

            if ((new RegExp(this.errorStrings.outsideRisk, 'i')).test(e.response.data.message)) {
              this.showModal(
                'priceOutsideError',
                this.str.done.price_outside_risk_boundaries,
              );
            }
          } else if (this.$isDev) {
            this.showModal(
              'priceOutsideError',
              this.str.done?.price_outside_risk_boundaries,
            );
          }

          // restore form_data
          this.form_data = { ...this.form_data, ...this.api_data };

          return Promise.reject(e);
        });

      return res.data;
    },
    /** prefill order data with form_data after successful api data call */
    updateOrderData(priceApiData = {}) {
      const riskDays = this.isMaximalMode
        ? this.form_data.contract_days - this.form_data.days : this.form_data.days;

      this.$set(this.form_data, 'risk_days', riskDays);

      const newApiData = { ...this.api_data };

      newApiData.threshold = Number(priceApiData.threshold);
      newApiData.prices = [...priceApiData.insurers]
        .sort((a, b) => a.price - b.price)
        .map((insr) => ({
          name: insr.name,
          price: Math.ceil(insr.price),
          id: insr.id,
          ...(insr.price < insr.alternative_price
            ? { alternative_price: Math.ceil(insr.alternative_price) } : {}),
        }));

      /** preselect insurer if it one */
      if (priceApiData.insurers?.length === 1) {
        this.$set(this.form_data, 'insurer', priceApiData.insurers[0]?.id);
        newApiData.price = priceApiData.insurers[0]?.price;
      } else {
        /** select first insurer */
        this.$set(this.form_data, 'insurer', newApiData.prices[0].id);
      }

      // set threshold to form_data
      this.$set(this.form_data, 'threshold', priceApiData.threshold);

      /* find relevant object from optimizer array
        * && is available object with "f_max_ratio_bundle_avg_hist_payout_bundle_min_price": true
        */
      if (this.optimizedData.some((el) => el.f_max_ratio_bundle_avg_hist_payout_bundle_min_price)) {
        const [optimized] = this.optimizedData
          .filter((el) => el.severity === this.form_data.severity)
          .filter((el) => el.risk_days === this.form_data.risk_days);
        if (optimized) {
          newApiData.payout_days_array = optimized.payout_days_array;
        }
      }

      // store
      this.$store.commit('SET_ORDER', newApiData);

      // upd order data (api_data) with form_data
      this.api_data = {
        ...newApiData,
        ...{
          severity: this.form_data.severity,
          max_payout: this.form_data.max_payout,
          days: this.form_data.days,
          risk_days: this.form_data.risk_days,
          daily_payout: this.form_data.daily_payout,
        },
      };
    },
    calcPayout() {
      // calc daily_payout or max_payout depending on the weather_direction
      if (this.isMaximalMode) {
        const dp = this.toCent(this.form_data.max_payout / this.form_data.days);
        this.$set(this.form_data, 'daily_payout', dp);
      } else {
        const mp = this.toCent(this.form_data.daily_payout
          * (this.api_data.contract_days - this.form_data.days));
        this.$set(this.form_data, 'max_payout', mp);
      }
    },
    /** reaction to changes to form data */
    async onFormChange() {
      // send form and get response to set api_data
      const priceApiData = await this.callPriceApi();

      // call optimizer api
      if (
        (this.isMaximalMode
          && Math.round(this.form_data.max_payout) !== Math.round(this.api_data.max_payout))
        || (!this.isMaximalMode && this.form_data.daily_payout !== this.api_data.daily_payout)
      ) {
        await this.callOptimizer();
      }

      this.updateOrderData(priceApiData);
    },
    /** compute historical payouts */
    // calcHistoricalPayout() {
    //   const historical = {};
    //   Object.entries(this.api_data.payout_days_array || {}).forEach(([key, val]) => {
    //     historical[key] = this.toCent(val * this.api_data.daily_payout * 100);
    //   });
    //   LogService.log('historical', historical);
    //   this.historical = historical;
    // },
    checkPayoutLimit(dailyPayout, maxPayout) {
      /*
      check inputs for limit value
      will return Object success.daily, success.max
      */
      const success = {
        daily: false,
        max: false,
      };
      success.daily = dailyPayout <= this.maxDailyPayout;
      success.max = maxPayout <= this.maxContractPayout;

      return success;
    },
    onChangeDays(days) {
      this.$v.form_data.days.$touch();
      if (this.$v.form_data.days.$error) return;

      const insuredDays = this.isMaximalMode ? days : this.form_data.contract_days - days;

      // recalculate dailyPayout
      const dailyPayout = this.getDailyPayout(this.form_data.max_payout, insuredDays);
      const maxPayout = dailyPayout * insuredDays;
      this.checkPayoutInputs(
        dailyPayout,
        maxPayout,
        insuredDays,
      );
    },
    /**
      input change handler
      show message if value limit over
    */
    checkPayoutInputs(
      dailyPayout,
      maxPayout,
      insuredDays,
    ) {
      this.$v.form_data.max_payout.$touch();
      if (this.$v.form_data.max_payout.$error) return;

      // check & set max payout limits
      const success = this.checkPayoutLimit(
        dailyPayout,
        maxPayout,
      );
      if (!success.daily) {
        this.$set(
          this.form_data,
          'max_payout',
          this.getMaximalPayoutBounded(
            this.maxDailyPayout,
            insuredDays,
            this.maxContractPayout,
          ),
        );
        this.$set(
          this.form_data,
          'daily_payout',
          this.getDailyPayoutBounded(
            this.form_data.max_payout, insuredDays, this.maxDailyPayout,
          ),
        );
        this.showModal(
          'priceError',
          this.format(
            this.str.done.error_price_outside_norain_daily,
            this.numToPriceFormat(this.maxDailyPayout, this.lang),
            insuredDays,
            this.numToPriceFormat(this.form_data.max_payout, this.lang),
          ),
          this.debouncedFormChange,
        );
      } else if (!success.max) {
        this.$set(this.form_data, 'max_payout', this.maxContractPayout);
        this.showModal(
          'priceError',
          this.format(
            this.str.done.error_price_outside_norain_max,
            this.numToPriceFormat(this.form_data.max_payout, this.lang),
          ),
          this.debouncedFormChange,
        );
      } else {
        this.calcPayout();
        this.debouncedFormChange();
      }
    },
    inputCancelThreshold() {
      // cancel changes the threshold input in from_data
      this.$set(this.form_data, 'threshold', this.api_data.threshold);
    },
    showModal(name, text, cb) {
      this.$set(this.modalsContent, name, { text, cb });
      this.$set(this.modalsOpened, name, true);
    },
    async next() {
      // next button handler
      // check validation
      this.$v.$touch();
      if (this.$v.$anyError) return;

      /** call api select insurer if route has query param 'many' */
      await this.selectInsurer(this.form_data.insurer);

      this.$router.push({ name: 'additional', params: { ...this.$route.params } });
    },
    back() {
      // back button handler
      if (['0', '1', '3'].includes(this.userStatus)) {
        let name = 'location';
        if (this.api_data.business_type === this.availableBusinessTypes.volksfest) name = 'rain';
        this.$router.push({ name, params: { ...this.$route.params } });
      }
    },
    changeCondition(link) {
      // handle for click on the selected condition section links
      if ('href' in link) {
        window.location = link.href;
      } else this.$router.push(link);
    },
    onSelectFromOutsideSet(selected) {
      const {
        days,
        severity,
        dailyPayout,
        maxPayout,
      } = selected;
      if (days !== this.api_data.days) {
        this.$set(this.form_data, 'days', days);
      }
      if (severity !== this.api_data.severity) {
        this.$set(this.form_data, 'severity', severity);
      }
      if (!this.isMaximalMode && dailyPayout !== this.api_data.daily_payout) {
        this.$set(this.form_data, 'daily_payout', dailyPayout);
      } else if (this.isMaximalMode && maxPayout !== this.api_data.max_dayout) {
        this.$set(this.form_data, 'max_payout', maxPayout);
        const dailyPayoutComp = this.getDailyPayout(maxPayout, days);
        this.$set(this.form_data, 'daily_payout', dailyPayoutComp);
      }

      this.onFormChange();
    },
    /** always push path such as '/home/done-business_type' */
    pushDataLayer() {
      const path = `${this.$route.path.replace(/\/$/, '')}-${this.api_data.business_type}`;
      const dataLayerItem = {
        event: 'Pageview',
        pagePath: path,
        pageTitle: metaData.done[this.lang].title,
        originalLocation: `${document.location.protocol}//\
${document.location.hostname}\
${path}`,
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(dataLayerItem);
    },
    onPayoutChange(event) {
      let insuredDays;
      let dailyPayout;
      if (this.isMaximalMode) {
        insuredDays = this.form_data.days;
        dailyPayout = this.getDailyPayout(event, this.form_data.days);
      } else {
        insuredDays = this.form_data.contract_days - this.form_data.days;
        dailyPayout = event;
      }
      const maxPayout = dailyPayout * insuredDays;
      this.checkPayoutInputs(
        dailyPayout,
        maxPayout,
        insuredDays,
      );
    },
  },
};
</script>
<style lang="scss">
  /*
  * animation list-vert
  */
  .list-vert-enter-active, .list-vert-leave-active {
    transition: all .3s;
  }
  .list-vert-enter, .list-vert-leave-to {
    opacity: 0;
    transform: scaleY(0);
  }
  .wet-levels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }
  .wet-levels-tile, .wet-levels-custom {
    flex: none;
    width: 32%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid var(--grey-light);
    box-shadow: 0 0 .5rem rgba(0,0,0,.1);
    color: var(--septenary);
    fill: currentColor;
    padding: .5rem 1rem;
    border-radius: .5rem;
    cursor: pointer;
    text-align: center;
    transition: all .3s ease-in-out;
  }
  .wet-levels-tile:hover, .wet-levels-custom:hover {
    color: var(--secondary);
    box-shadow: 0 5px .5rem rgba(0,0,0,.2);
  }
  .wet-levels-tile.active, .wet-levels-custom.active {
    background-color: var(--secondary);
    box-shadow: 0 0 .5rem #{"rgba(var(--secondary-rgb), 0.5)"};
    color: white;
    border: none;
  }
  .wet-levels-custom {
    width: 100%;
    margin-bottom: 1rem;
  }
  .wet-levels-tile-icon {
    flex: 1;
    width: 1.5rem;
  }
  .wet-levels-tile-title, .wet-levels-custom {
    font-weight: 700;
    font-size: .75rem;
  }
  .wet-done-number {
    color: var(--secondary);
    display: inline-block;
    text-align: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .75rem;
    font-size: .75rem;
    line-height: 1.5rem;
    margin-right: .75rem;
    border: 2px solid currentColor;
  }
  .wet-card {
    // box-shadow: 0 0 10px rgba(0,0,0,.1);
    // border: 1px solid var(--grey-light);
    border: 3px solid var(--primary);
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .wet-info-link {
    font-size: .75rem;
    color: var(--secondary);
    font-weight: 700;
  }
  .wet-done-payout {
    border-bottom: 1px solid var(--grey-light);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
</style>
