var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table-ui',{attrs:{"bodyHeight":220,"cols":['numdays', 'payout'],"head":{
    numdays: {
      text: `${_vm.header_number_of_days} ${_vm.rainy_days_str}`,
    },
    payout: {
      text: _vm.your_payout_text,
      align: 'right',
    },
  }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._l((_vm.rainy_days),function(item,index){return [_c('tr',{key:`${index}-wet-table-tr`,class:_vm.hasHistorical(item) ? 'wet-table-have-been' : ''},[_c('td',{attrs:{"colspan":"2"}},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-1"},[_c('strong',[_vm._v(_vm._s(item[0])+" ")]),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s([-1,1].includes(item[0]) ? _vm.rainy_day_str : _vm.rainy_days_str)+" ")])]),_c('div',{staticClass:"flex-shrink-0 text-right text-nowrap",class:index === 0 ? 'font-weight-bold is-size-5' : ''},[_vm._v(" "+_vm._s(_vm.numToPriceFormat(item[1], _vm.lang))+" "+_vm._s(_vm.currency)+" ")]),(_vm.hasHistorical(item[1]))?_c('div',{key:`${index}-wet-table-sub-tr`,staticClass:"is-size-7 text-primary text-right pt-2"},[_vm._v(" "+_vm._s(_vm.computeText(item))+" ")]):_vm._e()])])])])]})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }